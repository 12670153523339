<template>
 <div class="container">
  <div class="header">
     <div class="logo">
      <img src="../assets/imgs/logo.png" alt="">
    </div>
     <div class="navBtn" @click="navShow = !navShow"> 
      <img :src="navShow ? closeIcon : openIcon" alt="">
    </div>
    <div :class="navShow ? 'navCon animate__animated animate__fadeInRight' : 'navCon animate__animated animate__fadeOutRight'">
      <div v-show="navShow">
        <div class="navItems" v-for="(item,idx) in navItem" @click="chooseItem(idx)">
          <span :class="item.itemActive ? 'navItems-active':''">{{item.title}}</span>
        </div>
      </div>
    </div>
  </div>
  <swiper class="swiper" ref="mySwiper" :options="swiperOption">
    <swiper-slide class="option1">
      <div class="leftCon">
        <div class="detail">
          <div class="title">
            <p>嘉医有品，</p>
            <p>您触手可及的健康专家</p>
          </div>
          <div class="desc">
            <p>嘉医有品致力于打造专业的神经系统疾病医疗服务体系，通过构建中国首个专注于慢性功能性神经系统疾病领域的以实现“科技+服务”双轮驱动的全周期优质医疗服务网络。</p>
            <p>平台主要服务于运动障碍疾病与功能性神经系统疾病的诊疗，不但为医生提供专业化运动障碍疾病的诊疗工具，针对慢病实现全程管理、分级诊疗、提高诊疗效率与准确性；同时为患者提供更加便捷、实惠、专业的运动障碍疾病医疗服务，全面提高慢性病患者远期生活质量。</p>
          </div>
          <div class="downloadBtn" @click="dialogVisible = true">嘉医有品APP下载</div>
        </div>
      </div>
      <div class="rightCon">
      </div>
    </swiper-slide>
    <swiper-slide class="option2">
      <div class="topNav">
        <p class="navTitle">产品与服务</p>
        <p class="navDetail">
          <span 
          :class="currentNav.title == '在线医疗' ? 'active' : ''" @click="navClick(1)">在线医疗</span> / 
          <span @click="navClick(2)"
          :class="currentNav.title == '院外康复' ? 'active' : ''">院外康复</span> / 
          <span @click="navClick(3)"
          :class="currentNav.title == '健康档案' ? 'active' : ''">健康档案</span> / 
          <span @click="navClick(4)"
          :class="currentNav.title == '疾病知识' ? 'active' : ''">疾病知识</span></p>
      </div>
      <div class="bottomDetail">
        <div v-if="currentNav.type == 'image'">
          <p class="detailTitle">{{currentNav.title}}</p>
          <p class="detailDesc">{{currentNav.con}}</p>
          <p class="detailItem" v-for="(item,idx) in currentNav.detail"><img :src="item.icon" alt=""><span class="blue">{{item.title}}</span><span>{{item.desc}}</span></p>
        </div>
        <div v-if="currentNav.type == 'text'">
          <p class="detailTitle">{{currentNav.title}}</p>
          <p class="textDesc">{{currentNav.con}}</p>
          <p class="textItem" v-html="currentNav.detail"></p>
        </div>
        <img v-if="currentNav.title == '在线医疗'" class="itemBg" src="../assets/imgs/yiliao.png" alt="">
        <img v-if="currentNav.title == '院外康复'" class="itemBg kangfu" src="../assets/imgs/kangfu.png" alt="">
        <img v-if="currentNav.title == '健康档案'" class="itemBg health" src="../assets/imgs/health.png" alt="">
        <img v-if="currentNav.title == '疾病知识'" class="itemBg disease" src="../assets/imgs/disease.png" alt="">
      </div>
    </swiper-slide>
    <swiper-slide class="option3">
      <div class="top">
        <div class="leftDetail">
          <p class="leftTitle">嘉医有品</p>
          <p class="leftDesc">全国<span class="blue"> 300 </span>余家合作医院  </p>
          <p class="leftDesc">近<span class="blue"> 400 </span>位医生入驻   </p>
          <p class="leftDesc">覆盖<span class="blue"> 30 </span>个省份、直辖市及自治区</p>
          <p class="leftDesc">上线<span class="blue"> 36 </span>余城</p>
        </div>
        <div class="rightDetail">
          <img src="../assets/imgs/map.png" alt="">
        </div>
      </div>
      <div class="bottom">
        <div class="bottomTitle">名医推荐</div>
        <div class="bottomCard">
          <div class="cardDetail">
            <div class="detailLeft">
              <img src="../assets/imgs/xuxin.png" alt="">
            </div>
            <div class="detailRight">
                <p class="detailName">徐欣</p>
                <p class="detailPos">神经外科｜主任医师</p>
                <p class="detailPos">中国人民解放军总医院</p>
            </div>
          </div>
          <div class="cardDetail">
            <div class="detailLeft">
              <img src="../assets/imgs/cuizhiqiang.png" alt="">
            </div>
            <div class="detailRight">
                <p class="detailName">崔志强</p>
                <p class="detailPos">神经外科｜主任医师</p>
                <p class="detailPos">中国人民解放军总医院第一医学中心</p>
            </div>
          </div>
          <div class="cardDetail">
            <div class="detailLeft">
              <img src="../assets/imgs/lidianyou.png" alt="">
            </div>
            <div class="detailRight">
                <p class="detailName">李殿友</p>
                <p class="detailPos">神经外科｜副主任医师</p>
                <p class="detailPos">上海交通大学医学院附属瑞金医院</p>
            </div>
          </div>
          <div class="cardDetail">
            <div class="detailLeft">
              <img src="../assets/imgs/liweiguo.png" alt="">
            </div>
            <div class="detailRight">
                <p class="detailName">李卫国</p>
                <p class="detailPos">神经外科｜主任医师</p>
                <p class="detailPos">山东大学齐鲁医院</p>
            </div>
          </div>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide class="option4">
      <div class="top">
        <p class="topTitle">疾病科普</p>
        <p class="topSubtitle">每一篇科普都让患者少走弯路</p>
      </div>
      <div class="content">
        <swiper class="swiperLittle" :options="swiperLittleOption">
          <swiper-slide class="littleOption" v-for="(item,index) in diseaseArticles" :key="index">
            <div class="pics">
              <img :src="item.img" alt="">
            </div>
            <div class="picsDesc">
              <p class="picTitle">{{ item.title }}</p>
              <p class="picSubTitle">{{ item.desc }}</p>
              <p class="picMore">
                <a :href="item.link" target="_blank" rel="noopener noreferrer">查看更多</a>
                <img src="../assets/imgs/more.png" alt="">
              </p>
            </div>
          </swiper-slide>
          <!-- <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div> -->
        </swiper>
        <div class="button-prev">
          <img src="../assets/imgs/left.png" alt="">
        </div>
        <div class="button-next">
          <img src="../assets/imgs/right.png" alt="">
        </div>
      </div>
    </swiper-slide>
    <swiper-slide class="option5">
      <div class="left">
      </div>
      <div class="right">
        <p class="title">嘉医有品</p>
        <p class="text">嘉医有品是银川嘉医有品互联网医院的在线诊疗+健康管理的综合服务平台。服务涵盖了运动障碍疾病的科普、筛查、在线问诊、远程程控、处方/续方购药、慢病长期管理等；采用人工智能、大数据采集与统计分析，视频交互采集分析、物联网技术等高科技手段，优化运动障碍疾病全周期的诊疗服务与管理过程。产品以疾病诊疗为核心的系统解决方案，以远程程控为核心的术后患者管理体系，让高水平的医疗服务触手可及。</p>
        <p class="contant">联系我们</p>
        <div class="space">
        <div>
          <!-- <p class="footer"><img src="../assets/imgs/phone.png" alt=""><span>电话：010-6232-8044</span></p> -->
          <p class="footer"><img src="../assets/imgs/wechat.png" alt=""><span>微信号：JoinPins</span></p>
          <p class="footer"><img src="../assets/imgs/wechat.png" alt=""><span>公众号：嘉医有品</span></p>
        </div>
        <div>
          <p class="footer"><img src="../assets/imgs/phone.png" alt=""><span>自治区卫生热线：0951-12320</span></p>
          <p class="footer"><img src="../assets/imgs/phone.png" alt=""><span>银川市政府热线：0951-12345</span></p>
        </div>
        <div>
          <p class="footer"><img src="../assets/imgs/phone.png" alt=""><span>医院客服部主管及电话：小嘉 13260199120</span></p>
          <p class="footer"><img src="../assets/imgs/phone.png" alt=""><span>客户服务电话：400-606-6655</span></p>
        </div>
      </div>
       
        <div class="aboutus">关于我们</div>
      </div>
      <div class="copyright">
        <p>为了防范电信网络诈骗，如接到962110来电，请立即接听</p>
        <p>
          <a href="https://beian.miit.gov.cn/" target="_black">京ICP备2023007129号-1</a>
          <!-- <span>｜</span> -->
          <!-- <img src="../assets/imgs/police.png" />
          <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=64010502000163" target="_black">宁公网安备 64010502000163号</a> -->
          <span>｜北京嘉医有品医疗服务有限责任公司｜如需转载，请注明来源</span>
        </p>
      </div>
    </swiper-slide>
  </swiper>

   <div class="swiperBtnNext" v-if="currentIndex != 4">
    <img v-if="currentIndex == 2" src="../assets/imgs/next_fff.png" alt="">
    <img v-else src="../assets/imgs/next.png" alt="">
  </div>
  
  <div v-else @click="toTop" class="toTopBtn">
    <img src="../assets/imgs/top.png" alt=""><span>回到顶部</span>
  </div>
 
  <!-- 下载app弹窗 -->
  <el-dialog
  :visible.sync="dialogVisible"
  width="52%"
  :before-close="handleClose">
  <img class="closeDialog" @click="dialogVisible = false" src="../assets/imgs/dialogClose.png" alt="">
  <div class="title">
    <p class="titleCon">嘉医有品APP下载</p>
    <p class="titleDesc"><span class="yellow">扫描二维码</span>，快去下载嘉医有品APP吧!</p>
  </div>
  <div class="detail">
    <div class="imgs">
      <img src="../assets/imgs/patientCode.png" alt="">
      <span>患者端二维码</span>
    </div>
    <div class="imgs">
      <img src="../assets/imgs/doctorCode.png" alt="">
      <span>医生端二维码</span>
    </div>
  </div>
</el-dialog>
 </div>
</template>

<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { services, diseaseArticles } from "../assets/js/const.js";
export default {
  name: "Home",
  components: {
    swiper,
    swiperSlide
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
  data() {
    const _this = this;
    return {
      swiperOption: {
        loop: false,
        direction: "vertical",
        autoplay: false,
        mousewheel: true, // 鼠标控制;
        slidesPerView: 1,
        spaceBetween: 0,
        // 设置点击箭头
        navigation: {
          nextEl: ".swiperBtnNext"
          // prevEl: ".swiper-button-prev"
        },
        on: {
          slideChangeTransitionEnd() {
            const c_swiper = document.getElementsByClassName("swiper");
            _this.currentIndex = c_swiper[0].swiper.realIndex;
            _this.navItem.forEach((item, index) => {
              if (index === _this.currentIndex) {
                item.itemActive = true;
              } else {
                item.itemActive = false;
              }
            });
          }
        }
      },
      currentIndex: 0,
      swiperLittleOption: {
        slidesPerView: 3,
        spaceBetween: 30,
        slidesPerGroup: 3,
        navigation: {
          nextEl: ".button-next",
          prevEl: ".button-prev",
          disabledClass: "button-disabled"
        }
      },
      navShow: false,
      closeIcon: require("../assets/imgs/close.png"),
      openIcon: require("../assets/imgs/nav.png"),
      navItem: [
        { title: "首页", itemActive: true },
        { title: "产品与服务", itemActive: false },
        { title: "业务推广", itemActive: false },
        { title: "疾病科普", itemActive: false },
        { title: "关于我们", itemActive: false }
      ],
      dialogVisible: false,
      services: services,
      currentNav: {},
      diseaseArticles: diseaseArticles
    };
  },
  mounted() {
    // 导航栏设置默认值;
    this.currentNav = this.services[0];
  },
  methods: {
    // 导航栏选中状态展示;
    chooseItem(idx) {
      this.navItem.forEach((item, index) => {
        if (index === idx) {
          item.itemActive = true;
        } else {
          item.itemActive = false;
        }
      });
      this.currentIndex = idx;
      this.swiper.slideTo(idx, 1000, false);
    },
    handleClose(done) {},
    navClick(val) {
      switch (val) {
        case 1:
          this.currentNav = this.services[0];
          break;
        case 2:
          this.currentNav = this.services[1];
          break;
        case 3:
          this.currentNav = this.services[2];
          break;
        case 4:
          this.currentNav = this.services[3];
          break;
      }
    },
    toTop() {
      this.currentIndex = 0;
      this.chooseItem(0);
      this.swiper.slideTo(0, 1000, false);
    }
  }
};
</script>

<style lang="scss">
.container {
  width: 100%;
  height: 100%;

  .header {
    height: 84px;
    width: 100%;
    padding: 24px 120px;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    z-index: 90;
    background: transparent;

    .logo {
      // margin-top: 30px;
      img {
        width: 119px;
        height: 42px;
      }
    }

    .navBtn {
      right: 120px;
      height: 48px;
      position: fixed;
      right: 120px;
      top: 24px;
      z-index: 999;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .navCon {
      height: 100%;
      width: 15%;
      background-color: #3072f6;
      box-shadow: -2px 0px 6px 0px rgba(0, 0, 0, 0.1);
      padding: 275px 120px 0 0;
      position: fixed;
      right: 0;
      top: 0;

      .navItems {
        color: #fff;
        font-size: 24px;
        margin-bottom: 64px;
        font-weight: 400;
        text-align: right;
        cursor: pointer;

        &-active {
          padding-bottom: 8px;
          border-bottom: 2px solid #fff;
          font-size: 28px;
        }
      }
    }
  }

  .swiper-container {
    width: 100%;
    height: 100%;

    .option1 {
      padding-left: 120px;
      display: flex;
      justify-content: flex-start;
      width: 100%;
      height: 100% !important;

      .leftCon {
        width: 45%;
        background-color: #fff;
        padding-top: 12%;

        .detail {
          .title {
            color: #3072f6;
            font-weight: 500;
            font-size: 40px;
            line-height: 58px;
            margin-bottom: 24px;
          }

          .desc {
            color: #484848;
            font-size: 16px;
            line-height: 24px;
            width: 560px;
            p {
              margin-bottom: 32px;
            }
          }
          .downloadBtn {
            padding: 10px 16px;
            font-size: 14px;
            background: #3072f6;
            border-radius: 20px;
            border: 1px solid #3072f6;
            display: inline-block;
            color: #fff;
            cursor: pointer;
          }
        }
      }
      .rightCon {
        width: 55%;
        background: url("../assets/imgs/homebg.png") no-repeat;
        background-size: 100% 100%;
      }
    }

    .option2 {
      padding-top: 90px;
      position: relative;
      width: 100%;
      height: calc(100% - 90px) !important;
      .topNav {
        height: 334px;
        padding: 10% 0 0 20%;
        box-sizing: border-box;
        background: #3072f6;

        .navTitle {
          font-size: 40px;
          font-weight: 500;
          color: #fff;
          margin-bottom: 16px;
        }
        .navDetail {
          font-size: 20px;
          color: #e0e0e0;

          .active {
            font-weight: 600;
            border-bottom: 2px solid #fff;
            padding-bottom: 4px;
            color: #fff;
          }

          span {
            cursor: pointer;
          }
        }
      }

      .bottomDetail {
        padding: 32px 0 0 20%;
        .detailTitle {
          font-size: 26px;
          font-weight: 600;
          color: #3072f6;
          margin-bottom: 10px;
        }
        .detailDesc {
          font-size: 16px;
          color: #585858;
          margin-bottom: 32px;
        }
        .detailItem {
          color: #4a4a4a;
          margin-bottom: 24px;
          img {
            width: 32px;
            height: 32px;
            margin-right: 16px;
            vertical-align: middle;
          }
          .blue {
            color: #3072f6;
            margin-right: 8px;
          }
        }
        .itemBg {
          position: absolute;
          right: 16%;
          top: 17%;
          width: 392px;
          height: 706px;

          &.kangfu {
            width: 600px;
            height: 709px;
            right: 13%;
            top: 15%;
          }
          &.health {
            width: 600px;
            height: 704px;
            right: 13%;
            top: 15%;
          }
          &.disease {
            width: 600px;
            height: 701px;
            right: 13%;
            top: 15%;
          }
        }
        .textDesc {
          font-size: 20px;
          color: #585858;
          margin-bottom: 18px;
        }
        .textItem {
          font-size: 16px;
          width: 630px;
          color: #4a4a4a;
          line-height: 30px;
        }
      }
    }

    .option3 {
      background: #f5f5f5;
      position: relative;
      display: flex;
      justify-content: center;

      .top {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 24px;
        padding: 9% 0 6.2%;

        .leftDetail {
          .leftTitle {
            font-size: 40px;
            font-weight: 600;
            color: #4a4a4a;
            margin-bottom: 16px;
          }
          .leftDesc {
            font-size: 24px;
            font-weight: 600;
            color: #9b9b9b;
            line-height: 72px;
            width: 385px;

            .blue {
              color: #3072f6;
              font-size: 36px;
            }
          }
        }
        .rightDetail {
          margin-left: 207px;
          img {
            width: 680px;
            height: 480px;
          }
        }
      }
      .bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #3072f6;
        padding: 24px 0 3%;
        text-align: center;

        .bottomTitle {
          font-size: 32px;
          font-weight: 500;
          color: #fff;
          margin-bottom: 16px;
        }

        .bottomCard {
          display: flex;
          justify-content: center;
          .cardDetail {
            width: 318px;
            height: 147px;
            background-color: #fff;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            box-shadow: 0px 7px 35px 0px rgba(90, 97, 105, 0.1),
              0px 15px 22px 0px rgba(90, 97, 105, 0.1),
              0px 4px 8px 0px rgba(90, 97, 105, 0.12),
              0px 2px 3px 0px rgba(90, 97, 105, 0.11);
            border-radius: 10px;
            padding-left: 24px;
            margin-right: 24px;
            box-sizing: border-box;
            .detailLeft {
              margin-right: 8px;
              img {
                width: 80px;
                height: 80px;
              }
            }
            .detailRight {
              color: #9b9b9b;
              text-align: left;
              width: 202px;
              .detailName {
                font-weight: 600;
                font-size: 18px;
                margin-bottom: 6px;
                color: #565656;
              }
              .detailPos {
                font-size: 14px;
                line-height: 20px;
                width: 182px;
              }
            }
          }
        }
      }
    }
    .option4 {
      .top {
        padding: 107px 0 20px;
        width: 100%;
        text-align: center;
        background-color: #f5f5f5;

        .topTitle {
          font-size: 40px;
          font-weight: 500;
          color: #3072f6;
        }
        .topSubtitle {
          font-size: 20px;
          color: #565656;
          line-height: 45px;
        }
      }
      .content {
        padding: 64px 288px 0 310px;
        position: relative;

        .littleOption {
          // margin-right: 100px !important;
          .pics {
            width: 355px;
            height: 355px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .picsDesc {
            padding-left: 22px;
            .picTitle {
              width: 310px;
              font-size: 20px;
              font-weight: 600;
              color: #3072f6;
              line-height: 28px;
            }
            .picSubTitle {
              font-size: 16px;
              color: #9b9b9b;
              line-height: 30px;
            }
            .picMore {
              a {
                font-size: 16px;
                color: #3072f6;
                line-height: 22px;
                vertical-align: middle;
              }

              img {
                width: 20px;
                height: 20px;
                vertical-align: middle;
              }
            }
          }
        }

        .button-prev,
        .button-next {
          position: absolute;
          top: 208px;
          width: 48px;
          height: 48px;
          z-index: 9;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }
        }
        .button-prev {
          left: 280px;
        }
        .button-next {
          right: 315px;
        }
        .button-disabled {
          opacity: 0.2;
        }
      }
    }
    .option5 {
      background: #f5f5f5;
      padding: 111px 0 0 125px;
      display: flex;
      justify-content: flex-start;
      position: relative;

      .left {
        background: url("../assets/imgs/about.png") no-repeat;
        background-size: 100% 100%;
        width: 823px;
        height: 611px;
      }
      .right {
        padding: 40px;
        width: 974px;
        height: 436px;
        background-color: #fff;
        margin-left: -126px;
        margin-top: 36px;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        position: relative;
        box-sizing: border-box;

        .title {
          font-size: 32px;
          font-weight: 600;
          color: #3072f6;
          margin-bottom: 16px;
        }
        .text {
          font-size: 16px;
          color: #212121;
          line-height: 30px;
          margin-bottom: 32px;
        }
        .contant {
          font-size: 24px;
          font-weight: 500;
          color: #3072f6;
          line-height: 33px;
          margin-bottom: 24px;
        }
        .footer {
          margin-bottom: 16px;
          img {
            width: 24px;
            height: 24px;
            vertical-align: middle;
            margin-right: 8px;
          }
          span {
            font-size: 16px;
            color: #212121;
            line-height: 30px;
            vertical-align: middle;
          }
        }
        .aboutus {
          position: absolute;
          bottom: -92px;
          right: 16px;
          font-size: 40px;
          font-weight: 600;
          color: #4a4a4a;
        }
      }
      .copyright {
        position: absolute;
        bottom: 114px;
        left: 0;
        text-align: center;
        padding: 32px 0;
        width: 100%;
        background: #e8e8e8;
        font-size: 16px;
        color: #4a4a4a;
        p {
          line-height: 32px;

          img {
            width: 20px;
            height: 20px;
            vertical-align: middle;
          }
          a {
            color: #4a4a4a;
          }
          span,a {
            vertical-align: middle;
          }
        }
      }
    }
  }
  .swiperBtnNext {
    position: fixed;
    bottom: 48px;
    left: 120px;
    z-index: 99;
    cursor: pointer;

    img {
      width: 48px;
      height: 48px;
      vertical-align: middle;
    }
  }
  .toTopBtn {
    position: fixed;
    bottom: 48px;
    left: 120px;
    z-index: 99;
    cursor: pointer;
    margin-left: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #3072f6;
    img {
      width: 48px;
      height: 48px;
      vertical-align: middle;
    }
  }

  .el-dialog {
    background: #fff;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      position: relative;
      padding: 0;
      .closeDialog {
        position: absolute;
        right: 45px;
        top: 45px;
        width: 28px;
        height: 28px;
        cursor: pointer;
      }
      .title {
        height: 201px;
        background: url("../assets/imgs/downloadBg.png") no-repeat;
        background-size: 100% 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .titleCon {
          font-size: 30px;
          color: #3072f6;
          margin-bottom: 24px;
          font-weight: 500;
        }

        .titleDesc {
          color: #484848;
          font-size: 24px;
          font-weight: 500;
        }
        .yellow {
          color: #fdbe3e;
        }
      }

      .detail {
        padding: 40px 10%;
        display: flex;
        justify-content: space-between;

        .imgs {
          display: flex;
          flex-direction: column;
          justify-content: center;

          img {
            width: 300px;
            height: 300px;
          }

          span {
            text-align: center;
            font-size: 24px;
            font-weight: 500;
            color: #525252;
          }
        }
      }
    }
  }

  .space {
    display: flex;
    justify-content: space-between;
  }
}
</style>
