// 产品与服务属性;
const services = [{
    type: "image",
    title: "在线医疗",
    con: "足不出户，在线就诊",
    bg: require("../imgs/yiliao.png"),
    detail: [{
      icon: require("../imgs/yiliao_icon1.png"),
      title: "专家问诊",
      desc: "行业专家，在线咨询"
    },{
      icon: require("../imgs/yiliao_icon2.png"),
      title: "远程程控",
      desc: "有问题及时程控，减少奔波"
    },{
      icon: require("../imgs/yiliao_icon3.png"),
      title: "线上处方",
      desc: "线上拿处方，在家可购药"
    }]
  },
  {
    type: "image",
    title: "院外康复",
    con: "全流程病情管理，多手段提升疗效",
    bg: require("../imgs/kangfu.png"),
    detail: [{
      icon: require("../imgs/kangfu_icon1.png"),
      title: "康复锻炼",
      desc: "线上康复指导，在家也能做专业康复"
    },{
      icon: require("../imgs/kangfu_icon2.png"),
      title: "线上随访",
      desc: "生病不迷茫，医生全程跟踪管理"
    },{
      icon: require("../imgs/kangfu_icon3.png"),
      title: "疾病日记",
      desc: "医生更了解患者，患者更了解自己"
    }]
  },
  {
    type: "text",
    title: "健康档案",
    con: "病历不丢失，病史更清晰",
    bg: require("../imgs/health.png"),
    detail: "电子化的健康档案，详细记录患者的各项数据、资料、报告;<br />用户的健康信息将被更简单更快捷更安全的管理，减少了物理资源的消耗，扩展了传播途径，提供了更系统的管理方式和查看方式，用户将更好的管理自己的健康。"
  },
  {
    type: "text",
    title: "疾病知识",
    con: "每一次科普都让患者少走弯路",
    bg: require("../imgs/disease.png"),
    detail: "最通俗易懂的方式，向更多人普及最专业的疾病知识;<br />嘉医有品医学团队精心制作，结合国内外海量文献，为用户提供专业、易读的疾病科普文章；定期聘请三甲医院名医为患者答疑解惑，疾病的疑问，让医生来回答。"
}]
// 疾病科普轮播图属性;
const diseaseArticles = [{
	img: require("../imgs/pic1.png"),
	title: "帕金森如何早发现？辨别这四种特征，可快速自我排查！",
	desc: "嘉医有品医学团队｜帕金森专业医学科普",
	link: "https://mp.weixin.qq.com/s/MNUd2cFS1a1VGGXKjoyI1Q"
},{
	img: require("../imgs/pic2.png"),
	title: "从损毁到调节，带你了解帕金森外科发展史",
	desc: "嘉医有品医学团队｜帕金森专业医学科普",
	link: "https://mp.weixin.qq.com/s/iFG01bCMjkt-ZR4PFwfSsA"
},{
	img: require("../imgs/pic3.png"),
	title: "嗅觉失灵、便秘、失眠、焦虑，这些帕金森病非运动症状容易被忽视",
	desc: "嘉医有品医学团队｜帕金森专业医学科普",
	link: "https://mp.weixin.qq.com/s/PiyCEBfIPiJ-k4L0bXuH4g"
},{
	img: require("../imgs/pic4.png"),
	title: "这几种方法可以有效改善“剂末现象”",
	desc: "嘉医有品医学团队｜帕金森专业医学科普",
	link: "https://mp.weixin.qq.com/s/9a08mWHhBkyCnxq4mf_FbA"
},{
	img: require("../imgs/pic5.png"),
	title: "帕金森药品治疗原理，建议收藏！",
	desc: "嘉医有品医学团队｜帕金森专业医学科普",
	link: "https://mp.weixin.qq.com/s/gbmQR7PLpssR4I9ud3NtEQ"
},{
	img: require("../imgs/pic6.png"),
	title: "七成帕友都有的症状，但很少被认真治疗！",
	desc: "嘉医有品医学团队｜帕金森专业医学科普",
	link: "https://mp.weixin.qq.com/s/5BnPW0JtbTuJH5ha-6lGRw"
},{
	img: require("../imgs/pic7.png"),
	title: "最新早发型帕金森病的诊疗共识",
	desc: "嘉医有品医学团队｜帕金森专业医学科普",
	link: "https://mp.weixin.qq.com/s/7sSoKTWeT2ka7pPV6d0GQw"
},{
	img: require("../imgs/pic8.png"),
	title: "比摔倒更可怕的冻结步态，该怎么治疗？ ",
	desc: "嘉医有品医学团队｜帕金森专业医学科普",
	link:  "https://mp.weixin.qq.com/s/jWhZqNtPlIeyS4TfxrUflg"
},{
	img: require("../imgs/pic9.png"),
	title: " 疼痛十八变，无论怎么变，疼就是疼——帕金森病疼痛共计五类…",
	desc: "嘉医有品医学团队｜帕金森专业医学科普",
	link: "https://mp.weixin.qq.com/s/ew5qJEdlCa0TwcL9M04mZA "
},]



export {
	services,
	diseaseArticles
}